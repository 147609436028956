<template>
  <section class="ratings">
    <NuxtImg
      v-if="geographicZoneSlug"
      :src="GEOGRAPHIC_ZONE_BACKGROUND_IMAGES[geographicZoneSlug]"
      alt="rating background continent"
      ratio="none"
      class="ratings-background"
      loading="lazy"
    />
    <article class="title-container">
      <header class="title">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h2 v-if="title" class="text" v-html="DOMPurify.sanitize(title)"></h2>
        <div class="arrows-container">
          <NuxtLink v-if="isContextualizedPage" class="text" href="/">
            Voir tous les avis Japon
          </NuxtLink>
          <div class="items-center justify-center gap-2">
            <CarouselArrows
              v-if="carousel && ratings"
              :can-move-left="carousel.currentIndex !== 0"
              :can-move-right="carousel.currentIndex < ratings.length - carousel.slidesPerPage"
              @prev="carousel?.prevSlide"
              @next="carousel?.nextSlide"
            />
          </div>
        </div>
      </header>
      <div class="sub-title">
        <div class="averages">
          <Icon name="star" stroke="var(--color-gold)" width="24px" height="24px" fill="none" />
          <div class="average">{{ average }}/5</div>
        </div>
        <NuxtLink class="text" href="/">Sur la base de {{ count }} avis sur</NuxtLink>

        <NuxtImg
          src="/images/trust-pilot.png"
          alt="rating background continent"
          ratio="none"
          class="trust-pilot-pic h-[24px]"
          loading="lazy"
        />
      </div>
      <div class="arrows-mobile">
        <div class="items-center justify-center gap-2">
          <CarouselArrows
            v-if="carousel && ratings"
            class="md:flex"
            :can-move-left="carousel.currentIndex !== 0"
            :can-move-right="carousel.currentIndex < ratings.length - carousel.slidesPerPage"
            @prev="carousel?.prevSlide"
            @next="carousel?.nextSlide"
          />
        </div>
      </div>
    </article>

    <section class="content">
      <Carousel
        ref="carousel"
        :slides-data="
          ratings.map(rating => ({
            title: rating.title,
            image: rating.image,
            author: rating.author,
            content: rating.content,
            rating: rating.rating,
            date: rating.date,
            isActive: rating === ratings[carousel?.currentIndex ?? 0]
          }))
        "
        :slide-component="RatingCard"
      />
    </section>
  </section>
</template>

<script lang="ts" setup>
import DOMPurify from '@/lib/DOMPurify';
import { GEOGRAPHIC_ZONE_BACKGROUND_IMAGES } from '@/lib/variables';

import Carousel from '@/components/Carousel.vue';
import RatingCard from '@/components/RatingCard.vue';

import ROUTE_NAMES from '@/lib/types/enums/routeNames.enum';
import { Rating } from '@/lib/types/models/common';

defineProps<{
  title: string;
  count: number;
  average: number;
  ratings: Rating[];
  geographicZoneSlug?: string;
}>();

const carousel = ref<InstanceType<typeof Carousel> | null>(null);

const isContextualizedPage = computed(
  () =>
    useRoute().name === ROUTE_NAMES.PRODUCT ||
    useRoute().name === ROUTE_NAMES.COUNTRY ||
    useRoute().name === ROUTE_NAMES.CONTINENT
);
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/misc.scss';
@use '$/breakpoints.scss';
@use '$/spacings.scss';

.ratings {
  position: relative;

  overflow: hidden;
  flex-direction: column;
  gap: 48px;

  width: 100%;

  .ratings-background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .title-container {
    flex-direction: column;
    gap: 24px;
    padding: 0 spacings.$desktop-horizontal-lg;

    .title {
      justify-content: space-between;

      .text {
        grid-column: 2;
        font-size: 40px;
        font-weight: 600;
        white-space: pre;
      }

      .arrows-container {
        gap: 24px;
        align-items: center;
        justify-content: flex-end;

        .text {
          @include misc.bold-link();

          font-size: 20px;
          font-weight: 600;
          color: colors.$green;
          text-decoration: underline;
        }
      }
    }

    .arrows-mobile {
      display: none;
    }

    .sub-title {
      gap: 5px;
      align-items: center;
      justify-content: flex-start;
      font-size: 18px;

      .averages {
        gap: 8px;
      }

      .text {
        @include misc.bold-link();

        margin-right: 0;
      }

      .average {
        font-size: 32px;
        font-weight: 600;
        color: colors.$green;
      }
    }
  }

  .content {
    flex-direction: column;
    gap: 80px;
    justify-content: center;
    padding: 0 64px;
  }
}

@media (width <= 1040px) {
  .ratings {
    padding: 0 32px;

    .content {
      margin-left: 0;
    }
  }
}

@include breakpoints.mobile() {
  .ratings {
    gap: 24px;
    padding: 0;

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      padding: 0;

      .arrows-container {
        display: none;
      }

      .arrows-mobile {
        display: flex !important;
        gap: 12px;
        justify-content: center;
        width: 100%;
      }

      .title {
        flex-direction: column;

        .text {
          grid-column: 1;
          flex-wrap: wrap;
          font-size: 18px;
          white-space: break-spaces;
        }
      }

      .sub-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;

        .average {
          font-size: 24px;
        }
      }
    }

    .content {
      width: 100%;
      margin-left: 0;
      padding: 0;
    }
  }
}
</style>
